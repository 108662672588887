import { useProgress } from "@react-three/drei";
import "./Spinner.scss";

const Spinner = () => {
  // const { progress } = useProgress();

  return (
    <div className="Spinner">
      {/* <div>{Math.round(progress)} % loaded</div> */}
      <div className="Spinner-Inner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default Spinner;
