// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SceneName {
  width: 9vw;
  text-align: center;
  background-color: #fff;
  padding: 0.8vw 1.35vw;
  border-radius: 10px;
  font-size: 1.25vw;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  position: relative;
}
.SceneName p {
  margin: 0;
}

.SceneNameHitBox {
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.SceneNameHitBox.LakeBearbine {
  right: 0;
  width: 20vw;
  height: 40vh;
}

.SceneNameHitBox.HearingRoom {
  left: 0;
  width: 28vw;
  height: 28vh;
}

.SceneNameHitBox.HeartGarden {
  left: 0;
  width: 20vw;
  height: 35vh;
}`, "",{"version":3,"sources":["webpack://./src/components/Main/Main.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,kBAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;EACA,iBAAA;EACA,0CAAA;EACA,kBAAA;AACF;AACE;EACE,SAAA;AACJ;;AAGA;EACE,eAAA;EACA,kBAAA;EACA,SAAA;AAAF;;AAGA;EACE,QAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,OAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,OAAA;EACA,WAAA;EACA,YAAA;AAAF","sourcesContent":[".SceneName {\r\n  width: 9vw;\r\n  text-align: center;\r\n  background-color: #fff;\r\n  padding: 0.8vw 1.35vw;\r\n  border-radius: 10px;\r\n  font-size: 1.25vw;\r\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);\r\n  position: relative;\r\n\r\n  p {\r\n    margin: 0;\r\n  }\r\n}\r\n\r\n.SceneNameHitBox {\r\n  cursor: pointer;\r\n  position: absolute;\r\n  bottom: 0;\r\n}\r\n\r\n.SceneNameHitBox.LakeBearbine {\r\n  right: 0;\r\n  width: 20vw;\r\n  height: 40vh;\r\n}\r\n\r\n.SceneNameHitBox.HearingRoom {\r\n  left: 0;\r\n  width: 28vw;\r\n  height: 28vh;\r\n}\r\n\r\n.SceneNameHitBox.HeartGarden {\r\n  left: 0;\r\n  width: 20vw;\r\n  height: 35vh;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
