import { useEffect, useRef, useState } from "react";
import { Objects } from "components/Object/Object";
import { useAnimations, useGLTF } from "@react-three/drei";
import { Character } from "components/Character/Character";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

const Scene = ({
  data,
  selectedQuestion,
  setSelectedQuestion,
  sceneName,
}: any) => {
  const gltf = useGLTF(data.background as string);

  // Butterflies
  const gltfAsset = useGLTF(data.bgAsset ? (data.bgAsset as string) : "");
  const { animations } = gltfAsset;
  const { actions } = useAnimations(animations, gltfAsset.scene);
  useEffect(() => {
    if (actions && Object.keys(actions).length > 0) {
      const action = actions[Object.keys(actions)[0]];
      if (action) {
        action.play();
      }
    }
  }, [actions]);

  useEffect(() => {
    if (sceneName == "HearingRoom") {
      if (!Array.isArray(gltf)) {
        gltf.scene.children.map((child: any) => {
          if (child.isLight) {
            if (child.isDirectionalLight) child.intensity = 1.5;
            // if (child.isSpotLight) child.intensity = 0;

            console.log(child);
          }
        });
      }
    }

    if (sceneName == "LakeBearbine") {
      if (!Array.isArray(gltf)) {
        gltf.scene.children.map((child: any) => {
          if (child.name === "atmosphere000") child.visible = false;
          if (child.name === "atmosphere002") child.visible = false;
          if (child.name === "atmostphere001") child.visible = false;
          if (child.name === "atmostphere003") child.visible = false;

          if (child.name === "atmosphere001") child.position.y = -0.3;
        });
      }
    }
  }, [gltf]);

  // Camera transitions
  const { camera } = useThree();
  const [camera1, setCamera1] = useState<any>();
  const [camera2, setCamera2] = useState<any>();
  const [camera3, setCamera3] = useState<any>();
  const transitionProgress = useRef(0);
  const [transition, setTransition] = useState<"mount" | "question" | "empty">(
    "mount"
  );

  // Set each cameara
  useEffect(() => {
    if (!Array.isArray(gltf)) {
      gltf.scene.children.map((child: any) => {
        if (child.isCamera) {
          if (child.name === "Camera001") {
            setCamera1(child);
          } else if (child.name === "Camera002") {
            setCamera2(child);
          } else if (child.name === "Camera003") {
            setCamera3(child);
          }
        }
      });
    }
  }, [gltf]);

  // Set the initial camera position and quaternion
  useEffect(() => {
    if (camera1) {
      camera.position.set(
        camera1.position.x,
        camera1.position.y,
        camera1.position.z
      );
      camera.quaternion.set(
        camera1.quaternion.x,
        camera1.quaternion.y,
        camera1.quaternion.z,
        camera1.quaternion.w
      );

      (camera as THREE.PerspectiveCamera).fov = 23.7;
      camera.updateProjectionMatrix();
    }
  }, [camera1, camera]);

  const handleTransition = (
    startPos: THREE.Vector3,
    startQuat: THREE.Quaternion,
    endPos: THREE.Vector3,
    endQuat: THREE.Quaternion,
    lerpSpeed: number
  ) => {
    camera.position.lerpVectors(startPos, endPos, transitionProgress.current);
    camera.quaternion.slerpQuaternions(
      startQuat,
      endQuat,
      transitionProgress.current
    );
    transitionProgress.current += lerpSpeed;
  };

  // Check for the transition keyword and animate the camera
  useFrame((state, delta) => {
    const lerpSpeed = 0.5 * delta; // Adjust speed

    if (
      transition === "mount" &&
      camera1 &&
      camera2 &&
      transitionProgress.current < 1
    ) {
      handleTransition(
        camera1.position,
        camera1.quaternion,
        camera2.position,
        camera2.quaternion,
        lerpSpeed
      );
    } else if (
      transition === "question" &&
      camera2 &&
      camera3 &&
      transitionProgress.current < 1
    ) {
      handleTransition(
        camera2.position,
        camera2.quaternion,
        camera3.position,
        camera3.quaternion,
        lerpSpeed
      );
    } else if (
      transition === "empty" &&
      camera3 &&
      camera2 &&
      transitionProgress.current < 1
    ) {
      handleTransition(
        camera3.position,
        camera3.quaternion,
        camera2.position,
        camera2.quaternion,
        lerpSpeed
      );
    }
  });

  // Set the transition keyword and transitionProgress
  useEffect(() => {
    if (selectedQuestion) {
      setTransition("question");
    } else if (transition === "question") {
      // Only reset to 'empty' if it was in 'question' state
      setTransition("empty");
    }
    transitionProgress.current = 0;
  }, [selectedQuestion]);

  useFrame((state, delta) => {
    // Wave animation at the Lake
    if (sceneName === "LakeBearbine") {
      // @ts-ignore
      if (gltf.materials.shore_ripples.map.offset.y >= 1) {
        // @ts-ignore
        gltf.materials.shore_ripples.map.offset.y = 0;
      } else {
        // @ts-ignore
        gltf.materials.shore_ripples.map.offset.y += 0.001;
      }
    }
  });

  return (
    <>
      {data.characters.map((characterData: any, index: number) => (
        <Character
          key={characterData.file}
          index={index}
          modelPath={characterData.file}
          animPath={characterData.idleAnimation}
          characterData={characterData}
          questionData={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      ))}

      {/* Display background */}
      <primitive object={gltf.scene} />

      {/* Show butterflies only when the scene is not Hearing Room */}
      {gltfAsset && sceneName !== "HearingRoom" && (
        <primitive object={gltfAsset.scene} />
      )}

      <Objects
        distanceFactor={data.htmlDistanceFactor}
        questionsData={data.questions}
        sceneName={sceneName}
        selectedQuestion={selectedQuestion}
        setSelectedQuestion={setSelectedQuestion}
        objectPath={data.object}
      />
    </>
  );
};

export default Scene;
