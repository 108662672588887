import React, { useState, useEffect, useRef } from "react";
import { useLoader, useFrame } from "@react-three/fiber";
import {
  AnimationMixer,
  Euler,
  InterpolateDiscrete,
  LoopOnce,
  LoopRepeat,
  MathUtils,
  Vector2,
} from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { mapLinear } from "three/src/math/MathUtils";

interface CharacterProps {
  index: number;
  characterData: any;
  questionData: any;
  setSelectedQuestion: any;
  modelPath: string;
  animPath: string;
}

export function Character({
  index,
  characterData,
  questionData,
  setSelectedQuestion,
  modelPath,
  animPath,
}: CharacterProps) {
  const gltfModel = useLoader(GLTFLoader, modelPath);
  const gltfAnim = useLoader(GLTFLoader, animPath);
  const group = useRef();
  const [mixer, setMixer] = useState<AnimationMixer | null>(null);
  const frameDuration = 1 / 12; // 12fps in seconds

  useEffect(() => {
    let audio: HTMLAudioElement;
    let action: any;
    let mixer: AnimationMixer | null = null;
    let finishedHandler: any;

    if (gltfModel) {
      mixer = new AnimationMixer(gltfModel.scene);
      let idleAction: any = mixer.clipAction(gltfAnim.animations[0]);

      if (questionData) {
        const animationFile = questionData.animations.find(
          (q: any) => q.characterName === characterData.name
        );

        // Load the animation file
        const loader = new GLTFLoader();
        loader.load(animationFile.file, (anim) => {
          // Play the animation and audio for the question
          if (mixer) {
            action = mixer.clipAction(anim.animations[0]);

            // Set the interpolation type of all keyframe tracks to InterpolateDiscrete
            action.getClip().tracks.forEach((track: any) => {
              track.setInterpolation(InterpolateDiscrete);
            });
          }

          action.setLoop(LoopOnce, 1); // Set the loop mode to LoopOnce
          action.clampWhenFinished = true; // Set clampWhenFinished to true to pause the animation on the last frame

          audio = new Audio(questionData.audio);

          // Start the audio and animation at the same time
          Promise.all([
            action.crossFadeFrom(idleAction, 0.5, true),
            index === 0 ? audio.play() : Promise.resolve(),
            setTimeout(() => {
              action.play();
            }, 150),
          ]).catch((error) => console.error("Playback failed:", error));
        });

        // Listen to the 'finished' event on the mixer
        finishedHandler = () => {
          // Play the "idle" animation
          if (mixer) {
            idleAction.crossFadeTo(action, 0.5, true); // Blend from the question animation to the idle animation over 0.5 seconds
            idleAction.play();
          }

          // Stop the current action and audio
          action.stop();
          audio.pause();
          audio.currentTime = 0; // Optional: reset audio to the start

          setSelectedQuestion(null);
        };

        mixer.addEventListener("finished", finishedHandler);
      } else {
        // Play the idle animation continuously
        idleAction.setLoop(LoopRepeat);
        idleAction.play();
      }

      setMixer(mixer);
    }

    // Cleanup function
    return () => {
      if (mixer) {
        mixer.removeEventListener("finished", finishedHandler);
      }
      if (audio) {
        audio.pause();
        audio.currentTime = 0; // Optional: reset audio to the start
      }
      if (action) {
        action.stop();
      }
    };
  }, [gltfModel, gltfAnim, questionData]);

  const lastFrameTimeRef = useRef(0);
  const accumulator = useRef(0);

  useEffect(() => {
    if (questionData) {
      lastFrameTimeRef.current = 0;
      accumulator.current = 0;
    }
  }, [questionData]);

  useFrame((state, delta) => {
    if (mixer) {
      // Get the elapsed time since the application started in seconds
      const elapsedTime = state.clock.elapsedTime;

      // Calculate time since the last frame
      const delta = elapsedTime - lastFrameTimeRef.current;
      lastFrameTimeRef.current = elapsedTime;

      // Accumulate time
      accumulator.current += delta;

      while (accumulator.current >= frameDuration) {
        // Update the mixer by frameDuration each loop to avoid skipping frames on slow updates
        mixer.update(frameDuration);
        accumulator.current -= frameDuration;
      }
    }

    if (gltfModel && characterData.name !== "FishingRod") {
      let euler = new Euler();
      euler.setFromQuaternion(gltfModel.nodes.phoneme_picker.quaternion);
      let x =
        (mapLinear(
          Math.round((euler.z * MathUtils.RAD2DEG) / 36) * 36,
          -180,
          180,
          180,
          540
        ) +
          180) %
        360;

      euler.setFromQuaternion(gltfModel.nodes.emotion_picker.quaternion);
      let w =
        (mapLinear(
          Math.round((euler.z * MathUtils.RAD2DEG) / 90) * 90,
          -180,
          180,
          180,
          540
        ) +
          180) %
        360;

      const mouth = gltfModel.materials.spirit_bear_mouth;
      let uvX = (x % 180) / 180;

      let m = 0;
      if (x > 180) {
        m = 0.2;
      }
      let uvY = m + (w / 90) * 0.4;

      // @ts-ignore
      mouth.map.offset = new Vector2(uvX, uvY);

      // @ts-ignore
      // console.log(x, mouth.map.offset);
    }

    if (gltfModel && characterData.name === "FishingRod") {
      // @ts-ignore
      if (gltfModel.materials.fishing_rod_ripple.map.offset.y >= 1) {
        // @ts-ignore
        gltfModel.materials.fishing_rod_ripple.map.offset.y = 0;
      } else {
        // @ts-ignore
        gltfModel.materials.fishing_rod_ripple.map.offset.y += 0.001;
      }
    }
  });

  return <primitive ref={group} object={gltfModel?.scene} />;
}
