import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls, StatsGl } from "@react-three/drei";
import Main from "components/Main/Main";
import Scene from "components/Scene/Scene";
import data from "assets/data.json";
import Spinner from "components/Spinner/Spinner";
import { EffectComposer, N8AO } from "@react-three/postprocessing";
import TitleImg from "assets/graphics/bearshares_logo.svg";
import BackBtnImg from "assets/graphics/back_01.png";
import PressedBackBtnImg from "assets/graphics/back_01_pressed.png";
import HomeBtnImg from "assets/graphics/home.png";
import InfoBtnImg from "assets/graphics/info.png";

export let apiURL: string;

if (process.env.NODE_ENV === "development") {
  apiURL = "http://localhost:3001/api/";
} else {
  apiURL = window.location.origin + "/api/";
}

function App() {
  const [scene, setScene] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState<any>(null);
  const [showTextarea, setShowTextarea] = useState(false);
  const [question, setQuestion] = useState("");
  const [btnImgSrc, setBtnImgSrc] = useState(BackBtnImg);

  // State to track the loading status of Suspense component
  const [suspenseLoading, setSuspenseLoading] = useState(true);

  // Show Stats Panel
  const [enableDevTools, setEnableDevTools] = useState<boolean>(false);
  const [pressedKeys, setPressedKeys] = useState<string[]>([]);
  const [showAnswerBox, setShowAnswerBox] = useState(false);

  // Show the answer dialog box after the answerTimestamp
  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (selectedQuestion && selectedQuestion.answerTimestamp) {
      timerId = setTimeout(() => {
        setShowAnswerBox(true);
      }, selectedQuestion.answerTimestamp * 1000); // Convert to milliseconds
    }

    // Clean up the timer on unmount or when selectedQuestion changes
    return () => {
      if (timerId) clearTimeout(timerId);
      setShowAnswerBox(false);
    };
  }, [selectedQuestion]);

  const sendQuestion = () => {
    setShowTextarea(false);

    fetch(apiURL + "submit-question", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question,
      }),
    })
      .then((res) => res.text())
      .then((res) => console.log(res))
      .catch((err) => console.error(err));

    setQuestion("");
  };

  // Handle key down
  useEffect(() => {
    document.addEventListener("keydown", keyPressed);
    return () => {
      document.removeEventListener("keydown", keyPressed);
    };
  });

  const keyPressed = (e: KeyboardEvent) => {
    const newPressedKeys = [...pressedKeys, e.key];
    const cmdString = newPressedKeys.join("");
    // console.debug("command", cmdString);

    // functions
    const toggleDevTools = function () {
      setEnableDevTools(!enableDevTools);
    };

    // this is all shortcuts
    const keyBinds = {
      "[][]": toggleDevTools,
    };

    // from chatgpt
    function escapeRegExp(input: string) {
      return input.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    for (let idx in Object.keys(keyBinds)) {
      var key = Object.keys(keyBinds)[idx];
      if (RegExp(escapeRegExp(key)).test(cmdString)) {
        keyBinds[key as keyof typeof keyBinds]();
        setPressedKeys([]);
        return;
      }
    }

    setPressedKeys(newPressedKeys);
  };

  function calculateDimensions() {
    return window.innerWidth > window.innerHeight * (16 / 9)
      ? { width: window.innerHeight * (16 / 9), height: window.innerHeight }
      : { width: window.innerWidth, height: window.innerWidth * (9 / 16) };
  }

  const [dimensions, setDimensions] = useState(calculateDimensions());

  useEffect(() => {
    function handleResize() {
      setDimensions(calculateDimensions());
    }

    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBackButtonUp = () => {
    setBtnImgSrc(BackBtnImg);
    if (selectedQuestion) {
      setSelectedQuestion(null);
    } else {
      window.location.reload();
    }
  };

  // Show the title, speech bubble and info button when the loading is done
  useEffect(() => {
    if (!scene) {
      // This useEffect will run once the child components inside Suspense render
      setSuspenseLoading(false);
    }
  }, [scene]);

  return (
    <div className="App">
      <div id="landscape-only">
        <h2>This Application is best used in a landscape mode.</h2>
      </div>

      <Suspense fallback={<Spinner />}>
        <div
          className="CanvasContainer"
          style={{ width: dimensions.width, height: dimensions.height }}
        >
          <Canvas
            onCreated={({ gl }) => {
              gl.shadowMap.enabled = false; // Disable shadows
              gl.toneMappingExposure = 1;
            }}
            camera={{ near: 0.1, far: 100 }}
          >
            <ambientLight intensity={Math.PI / 1.5} />
            {/* NOTE: Eventually, change this part to turn off the EffectComposer when the framerate is slow (something like 24fps). */}
            {/* <EffectComposer enableNormalPass={false} multisampling={8}>
              <N8AO
                aoRadius={20}
                distanceFalloff={0.2}
                intensity={6}
                screenSpaceRadius
                halfRes
              />
            </EffectComposer> */}

            {scene ? (
              <Scene
                // @ts-ignore
                data={data[scene]}
                selectedQuestion={selectedQuestion}
                setSelectedQuestion={setSelectedQuestion}
                sceneName={scene}
              />
            ) : (
              <Main setScene={setScene} />
            )}

            {/* Debugging */}
            {enableDevTools && (
              <>
                <StatsGl />
                <OrbitControls target={[0, 0, 0]} zoomSpeed={0.65} />
                <Html>
                  <p className="change-log">
                    Changed: 15:59 PT on July 29, 2024
                  </p>
                </Html>
              </>
            )}
          </Canvas>

          {!suspenseLoading && !scene && (
            <div className="Title">
              <img src={TitleImg} alt="title" />
            </div>
          )}

          <div
            className="TopContainer"
            style={{
              gridTemplateColumns: `${dimensions.width / 9}px ${
                (dimensions.width / 9) * 8
              }px`,
            }}
          >
            {scene && (
              <div
                className="HomeButton"
                // onMouseDown={() => setBtnImgSrc(PressedBackBtnImg)}
                onMouseUp={() => window.location.reload()}
                // onTouchStart={() => setBtnImgSrc(PressedBackBtnImg)}
                onTouchEnd={() => window.location.reload()}
              >
                <img src={HomeBtnImg} alt="home-button" />
              </div>
            )}
          </div>

          <div
            className="BottomContainer"
            style={{
              gridTemplateColumns: `${dimensions.width / 9}px ${
                (dimensions.width / 9) * 8
              }px`,
            }}
          >
            {scene && (
              // NOTE: This button will reload the page instead of emptying the scene state.
              // If the state is emptied, the main menu 3D model won't be displayed for an unknown reason.
              <div
                className="BackButton"
                onMouseDown={() => setBtnImgSrc(PressedBackBtnImg)}
                onMouseUp={handleBackButtonUp}
                onTouchStart={() => setBtnImgSrc(PressedBackBtnImg)}
                onTouchEnd={handleBackButtonUp}
              >
                <img src={btnImgSrc} alt="back-button" />
              </div>
            )}

            {/* Display answerText if a question is selected */}
            {showAnswerBox && selectedQuestion && (
              <div className="AnswerBox">
                <p>{selectedQuestion.answerText}</p>
              </div>
            )}
          </div>

          {!suspenseLoading && !scene && (
            <>
              <div
                className="SpeechBubbleBox"
                style={{
                  top: `${dimensions.height / 2.6}px`,
                  left: `${dimensions.width / 1.57}px`,
                }}
              >
                <div className="SpeechBubble">
                  <div>
                    <p>Hi! I'm Spirit Bear.</p>
                    <p>Click on a location</p>
                    <p>to ask me a question.</p>
                  </div>
                  {/* Hi! I'm Spirit Bear. Click on a location to ask me a question. */}
                </div>
              </div>

              <div className="InfoButton">
                <a href="https://fncaringsociety.com/bear-shares">
                  <img src={InfoBtnImg} alt="info-button" />
                </a>
              </div>
            </>
          )}
        </div>

        {/* NOTE: There won't be a question submission form for this iteration. */}
        {/* {showTextarea ? (
          <div className="SubmitQuestionTextarea">
            <textarea
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>
            <div
              className="SubmitQuestionButton"
              onClick={() => {
                sendQuestion();
              }}
            >
              <p>Submit</p>
            </div>
          </div>
        ) : (
          <div
            className="SubmitQuestionButton"
            onClick={() => setShowTextarea(true)}
          >
            <p>Submit a Question</p>
          </div>
        )} */}
      </Suspense>
    </div>
  );
}

export default App;
