// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Questions {
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Questions p {
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-text-select: none;
  -webkit-user-select: none;
}

.Questions.HeartGarden p,
.Questions.LakeBearbine p {
  color: #fff;
  text-shadow: -0.1vw -0.1vw 0 #000, 0.1vw -0.1vw 0 #000, -0.1vw 0.1vw 0 #000, 0.1vw 0.1vw 0 #000, -0.15vw -0.15vw 0 #000, 0.15vw -0.15vw 0 #000, -0.15vw 0.15vw 0 #000, 0.15vw 0.15vw 0 #000;
}

.Questions.HeartGarden {
  width: 15vw;
  height: 25vh;
  border-radius: 30px;
  margin-top: -1.5vw;
}
.Questions.HeartGarden p {
  width: 11vw;
  line-height: 4.2vh;
}

.Questions.HearingRoom {
  width: 10vw;
  height: 28vh;
}
.Questions.HearingRoom p {
  width: 9vw;
}

.Questions.LakeBearbine {
  width: 20vw;
  height: 20vh;
}
.Questions.LakeBearbine p {
  width: 10vw;
}`, "",{"version":3,"sources":["webpack://./src/components/Object/Object.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,kBAAA;EACA,iBAAA;EACA,sBAAA;EACA,yBAAA;EACA,yBAAA;AACJ;;AAKE;;EACE,WAAA;EACA,2LAAA;AADJ;;AAOA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;AAJF;AAME;EACE,WAAA;EACA,kBAAA;AAJJ;;AAQA;EACE,WAAA;EACA,YAAA;AALF;AAOE;EACE,UAAA;AALJ;;AASA;EACE,WAAA;EACA,YAAA;AANF;AAQE;EACE,WAAA;AANJ","sourcesContent":[".Questions {\r\n  cursor: pointer;\r\n  outline: none;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n\r\n  p {\r\n    text-align: center;\r\n    user-select: none; // chrome and Opera\r\n    -moz-user-select: none; // Firefox\r\n    -webkit-text-select: none; // IOS Safari\r\n    -webkit-user-select: none; // Safari\r\n  }\r\n}\r\n\r\n.Questions.HeartGarden,\r\n.Questions.LakeBearbine {\r\n  p {\r\n    color: #fff;\r\n    text-shadow: -0.1vw -0.1vw 0 #000, 0.1vw -0.1vw 0 #000, -0.1vw 0.1vw 0 #000,\r\n      0.1vw 0.1vw 0 #000, -0.15vw -0.15vw 0 #000, 0.15vw -0.15vw 0 #000,\r\n      -0.15vw 0.15vw 0 #000, 0.15vw 0.15vw 0 #000;\r\n  }\r\n}\r\n\r\n.Questions.HeartGarden {\r\n  width: 15vw;\r\n  height: 25vh;\r\n  border-radius: 30px;\r\n  margin-top: -1.5vw;\r\n\r\n  p {\r\n    width: 11vw;\r\n    line-height: 4.2vh;\r\n  }\r\n}\r\n\r\n.Questions.HearingRoom {\r\n  width: 10vw;\r\n  height: 28vh;\r\n\r\n  p {\r\n    width: 9vw;\r\n  }\r\n}\r\n\r\n.Questions.LakeBearbine {\r\n  width: 20vw;\r\n  height: 20vh;\r\n\r\n  p {\r\n    width: 10vw;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
