import React, { useState, useEffect, useCallback } from "react";
import { useAnimations, Html } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import * as THREE from "three";
import "./Object.scss";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

interface ObjectProps {
  distanceFactor: number;
  questionsData: any;
  sceneName: string;
  selectedQuestion: any;
  setSelectedQuestion: (question: any) => void;
  objectPath: string;
}

const regularFontSize = "1.25vw";

export function Objects({
  distanceFactor,
  questionsData,
  sceneName,
  selectedQuestion,
  setSelectedQuestion,
  objectPath,
}: ObjectProps) {
  const gltfObj = useLoader(GLTFLoader, objectPath);
  const gltfBinderPos = useLoader(
    GLTFLoader,
    "assets/models/objects/tribunal_hearing_room_object-positions.gltf"
  );
  const { nodes: binderNodes } = gltfBinderPos;

  const [gltfAnimation, setGltfAnimation] = useState<any>();
  const [visible, setVisible] = useState(true);

  const { nodes, animations } = gltfObj;
  const { actions: idleActions } = useAnimations(animations, gltfObj.scene);
  const { actions: selectedActions } = useAnimations(
    gltfAnimation ? gltfAnimation.animations : [],
    gltfObj.scene
  );

  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<
    number | null
  >(null);
  const [fontSize, setFontSize] = useState<string>(regularFontSize);

  // Load the selected animation, if any
  useEffect(() => {
    if (selectedQuestion && selectedQuestion.objAnimation) {
      const loader = new GLTFLoader();
      loader.load(selectedQuestion.objAnimation, setGltfAnimation);
    } else {
      setGltfAnimation(null);
    }
  }, [selectedQuestion]);

  // Manage visibility
  useEffect(() => {
    setVisible(!selectedQuestion);
  }, [selectedQuestion]);

  // Handle animations
  useEffect(() => {
    let idleAction = Object.values(idleActions)[0] as THREE.AnimationAction;
    let selectedAction = gltfAnimation
      ? (Object.values(selectedActions)[0] as THREE.AnimationAction)
      : null;
    let mixer: THREE.AnimationMixer | null = null;
    let intervalId: NodeJS.Timeout | null = null;
    const intervalDuration: Record<string, number> = {
      LakeBearbine: 10000,
      HeartGarden: 11000,
      HearingRoom: 11330,
    };

    // Helper function to clear existing interval
    const clearExistingInterval = () => {
      if (intervalId) {
        clearInterval(intervalId);
        intervalId = null;
      }
    };

    const resetToIdle = () => {
      if (selectedAction) selectedAction.stop();
      if (idleAction) {
        idleAction.reset().play();
        clearExistingInterval();
        intervalId = setInterval(() => {
          idleAction.stop().play();
        }, intervalDuration[sceneName]);
      }
    };

    if (selectedQuestion && selectedAction) {
      if (idleAction) idleAction.stop();
      selectedAction.reset().play();
      mixer = new THREE.AnimationMixer(selectedAction.getRoot());
      mixer.addEventListener("finished", () => {
        selectedAction.stop();
        resetToIdle();
      });
    } else {
      resetToIdle();
    }

    // Cleanup function
    return () => {
      clearExistingInterval();
      if (mixer) {
        mixer.removeEventListener("finished", resetToIdle);
        mixer.stopAllAction();
      }
    };
  }, [selectedQuestion, idleActions, gltfAnimation, selectedActions]);

  // Generate Html elements
  const elements = useCallback(() => {
    const elmNodes = sceneName === "HearingRoom" ? binderNodes : nodes;
    return Object.keys(elmNodes)
      .filter((node) => node.includes("Object"))
      .map((node, index) => (
        <group
          key={node + sceneName}
          position={[
            elmNodes[node].position.x,
            elmNodes[node].position.y,
            elmNodes[node].position.z,
          ]}
        >
          {visible && (
            <Html center distanceFactor={distanceFactor} key={`html-${node}`}>
              <div
                onMouseDown={() => setSelectedQuestionIndex(index)}
                onMouseUp={() => {
                  setSelectedQuestionIndex(null);
                  setSelectedQuestion(questionsData[index]);
                }}
                onTouchStart={() => setSelectedQuestionIndex(index)}
                onTouchEnd={() => {
                  setSelectedQuestionIndex(null);
                  setSelectedQuestion(questionsData[index]);
                }}
                className={"Questions " + sceneName}
              >
                <p
                  style={{
                    fontSize:
                      index === selectedQuestionIndex
                        ? "1.55vw"
                        : regularFontSize,
                  }}
                >
                  {questionsData[index].questionText}
                </p>
              </div>
            </Html>
          )}
        </group>
      ));
  }, [
    nodes,
    sceneName,
    visible,
    questionsData,
    binderNodes,
    fontSize,
    selectedQuestionIndex,
  ]);

  return (
    <>
      <primitive object={gltfObj.scene} />
      {elements()}
    </>
  );
}
