import React, { useEffect, useState } from "react";
import { Html, useGLTF } from "@react-three/drei";
import "./Main.scss";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";

interface MainProps {
  setScene: React.Dispatch<React.SetStateAction<string>>;
}

const label = {
  Object_1: { label: "Lake Bearbine", scene: "LakeBearbine" },
  Object_2: { label: "Tribunal Hearing Room", scene: "HearingRoom" },
  Object_3: { label: "Heart Garden", scene: "HeartGarden" },
};

function Main({ setScene }: MainProps) {
  const gltfBg = useGLTF("assets/models/backgrounds/set_main_menu.gltf");
  const gltfModel = useGLTF(
    "assets/models/characters/spirit_bear_rig_low.gltf"
  );
  const gltfAnim = useGLTF(
    "assets/animations/MainMenu/main_menu_idle_spirit_bear.gltf"
  );

  // Check if models are loaded
  const [modelsLoaded, setModelsLoaded] = useState(false);
  useEffect(() => {
    if (gltfModel) {
      setModelsLoaded(true);
    }

    if (!Array.isArray(gltfBg)) {
      gltfBg.scene.children.map((child: any) => {
        if (child.name === "atmosphere000") child.visible = false;
        if (child.name === "atmosphere002") child.visible = false;

        if (child.name === "atmosphere001") child.position.y = -0.3;
      });
    }
  }, [gltfModel]);

  // Play the idle animation
  const [mixer, setMixer] = useState<THREE.AnimationMixer | null>(null);
  useEffect(() => {
    if (gltfModel.scene && gltfAnim.animations[0]) {
      const mixer = new THREE.AnimationMixer(gltfModel.scene);
      setMixer(mixer);

      const action = mixer.clipAction(gltfAnim.animations[0]);
      action.play();
    }
  }, [gltfModel, gltfAnim]);

  useFrame((state, delta) => {
    mixer?.update(delta);

    // @ts-ignore
    if (gltfBg.materials.shore_ripples.map.offset.y >= 1) {
      // @ts-ignore
      gltfBg.materials.shore_ripples.map.offset.y = 0;
    } else {
      // @ts-ignore
      gltfBg.materials.shore_ripples.map.offset.y += 0.001;
    }
  });

  // Background scene
  const [cache, setCache] = useState<any>();
  let scene: any = null;
  if (!Array.isArray(gltfBg)) {
    scene = gltfBg.scene;
  }

  // Set the cameara
  const { camera } = useThree();
  useEffect(() => {
    if (!Array.isArray(gltfBg)) {
      scene.children.map((child: any) => {
        if (child.isCamera) {
          camera.position.set(
            child.position.x,
            child.position.y,
            child.position.z
          );
          camera.quaternion.set(
            child.quaternion.x,
            child.quaternion.y,
            child.quaternion.z,
            child.quaternion.w
          );

          (camera as THREE.PerspectiveCamera).fov = child.fov;
          camera.updateProjectionMatrix();
        }
      });
    }
  }, [gltfBg]);

  // Set the labels
  useEffect(() => {
    if (scene && !cache) {
      const elements: JSX.Element[] = [];
      let objIndex = 0;

      scene.children.map((child: any) => {
        if (child.name.includes("Object")) {
          elements.push(
            <group
              key={label[child.name as keyof typeof label].label}
              position={[child.position.x, child.position.y, child.position.z]}
            >
              <Html center>
                <div
                  className="SceneName"
                  onTouchEnd={(e) => {
                    e.stopPropagation();
                    setScene(label[child.name as keyof typeof label].scene);
                  }}
                  onMouseUp={(e) => {
                    e.stopPropagation();
                    setScene(label[child.name as keyof typeof label].scene);
                  }}
                >
                  <p>{label[child.name as keyof typeof label].label}</p>
                  <div
                    className={
                      "SceneNameHitBox " +
                      label[child.name as keyof typeof label].scene
                    }
                  ></div>
                </div>
              </Html>
            </group>
          );
          objIndex++;
        }
      });

      setCache(<primitive object={scene}>{elements}</primitive>);
    }
  }, [scene, cache]);

  return (
    <>
      {modelsLoaded && (
        <>
          {/* Display model */}
          <primitive object={gltfModel?.scene} />

          {/* Display background */}
          {cache && cache}
        </>
      )}
    </>
  );
}

export default Main;
