// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.Spinner-Inner {
  display: block;
  position: relative;
  width: 6rem;
  height: 6rem;
}
.Spinner-Inner div {
  animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border: 0.6rem solid #fff;
  border-color: #fff transparent transparent transparent;
  border-radius: 50%;
  box-sizing: border-box;
  display: block;
  margin: 0.6rem;
  position: absolute;
  width: 6rem;
  height: 6rem;
}
.Spinner-Inner div:nth-child(1) {
  animation-delay: -0.45s;
}
.Spinner-Inner div:nth-child(2) {
  animation-delay: -0.3s;
}
.Spinner-Inner div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Spinner/Spinner.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AACI;EACE,0DAAA;EACA,yBAAA;EACA,sDAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACN;AACM;EACE,uBAAA;AACR;AAEM;EACE,sBAAA;AAAR;AAGM;EACE,uBAAA;AADR;;AAOA;EACE;IACE,uBAAA;EAJF;EAMA;IACE,yBAAA;EAJF;AACF","sourcesContent":[".Spinner {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n\r\n  &-Inner {\r\n    display: block;\r\n    position: relative;\r\n    width: 6rem;\r\n    height: 6rem;\r\n\r\n    div {\r\n      animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\r\n      border: 0.6rem solid #fff;\r\n      border-color: #fff transparent transparent transparent;\r\n      border-radius: 50%;\r\n      box-sizing: border-box;\r\n      display: block;\r\n      margin: 0.6rem;\r\n      position: absolute;\r\n      width: 6rem;\r\n      height: 6rem;\r\n\r\n      &:nth-child(1) {\r\n        animation-delay: -0.45s;\r\n      }\r\n\r\n      &:nth-child(2) {\r\n        animation-delay: -0.3s;\r\n      }\r\n\r\n      &:nth-child(3) {\r\n        animation-delay: -0.15s;\r\n      }\r\n    }\r\n  }\r\n}\r\n\r\n@keyframes spin {\r\n  0% {\r\n    transform: rotate(0deg);\r\n  }\r\n  100% {\r\n    transform: rotate(360deg);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
